angular.module('app')
    .filter('duration', ['$filter', 'moment', function ($filter, moment) {
        return function (duration) {

            if (angular.isUndefined(duration)) return '';

            if (duration.indexOf('.') >= 0) {
                return moment.duration(duration.substr(0, duration.indexOf('.')) + 'S').minutes();
            }

            return moment.duration(duration).minutes();

        };
    }]);